import React, { Component } from 'react';
import HeroProduct from '../HeroProduct';
import SecondPanel from '../SecondPanel';
import ThirdPanel from '../ThirdPanel';

export default class LandingPage extends Component {

  render () {
    return (
      <div>
        <HeroProduct />
        <SecondPanel />
        <ThirdPanel />
      </div>
    );
  }
}