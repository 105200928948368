import React, { Component } from 'react';
import styles from './Checkout.module.css';

export default class Checkout extends Component {

  render () {
    return (
      <div className={ styles.panel }>
      </div>
    );
  }
}