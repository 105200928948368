import React, { Component } from 'react';
import styles from './ThirdPanel.module.css';

export default class ThirdPanel extends Component {

  render () {
    return (
      <div id='third-panel' className={ styles.panel }>
        
      </div>
    );
  }
}