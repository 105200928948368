import React, { Component } from 'react';
import styles from './HeroProduct.module.css';
import Grid from '@material-ui/core/Grid';
import cart from '../ShoppingCart/cart.json';
import ProductContext from '../context/product-context';
import NewLink from '../Router/NewLink';

export default class HeroProduct extends Component {
  componentDidMount() {
  }
  render () {
    return (
      <ProductContext.Provider
        value={{
          productName: cart[0].item,
          productId: cart[0]._id,
          productDescription: cart[0].description,
          image: cart[0].img,
        }}
      >
        <div id='hero-product' className={ styles.panel }>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            className={ styles.grid }
          >
            <div className={ `${styles.imgWrapper} animated bounceInUp` } >
              <NewLink to={ `/product/${cart[0]['short-name']}` } title={(
                <img
                  alt='Hero shot'
                  className={ styles.heroImg } 
                  src={ cart[0].img }/>
              )}/>
            </div>
            <h1 id='on-sale' className={ `${styles.heroHeading} animated bounceInUp` } >On sale now!</h1>
          </Grid>
        </div>
      </ProductContext.Provider>
    );
  }
}