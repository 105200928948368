import React from 'react';
import { Router, Link } from 'react-router-dom';
import history from '../history';

const NewLink = React.memo(props => (
  <Router history={ history }>
    <Link to={ props.to } >{ props.title }</Link>
  </Router>
));

export default NewLink;