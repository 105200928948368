import React, { Component } from 'react';

import PropTypes from 'prop-types';
import * as mUi from '../SharedComnponents/components'
import NavStyles from './Navbar.module.css';
import ShoppingCart from '../ShoppingCart';
import cart from '../ShoppingCart/cart.json';
// import { Router } from "react-router-dom";
import NewLink from '../Router/NewLink';
// import { HashLink as Link } from 'react-router-hash-link';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: mUi.fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: mUi.fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

class Navbar extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    shoppingCartAnchorEl: null,
    loggedIn: false,
    isShoppingCartOpen: false,
  };

  componentWillUnmount() {
    this.setState({
      anchorEl: null,
      mobileMoreAnchorEl: null,
      shoppingCartAnchorEl: null,
      isShoppingCartOpen: false,
    })
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  openShoppingCart = event => {
    this.setState({ shoppingCartAnchorEl: event.currentTarget });
  };

  handleClickAway = () => this.setState({ isShoppingCartOpen: false })
  
  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const {
      anchorEl,
      mobileMoreAnchorEl,
      shoppingCartAnchorEl,
      loggedIn,
      isShoppingCartOpen,
    } = this.state;
    
    const display = {
      display: loggedIn ? 'block' : 'none',
    }

    const classes = { ...this.props.classes, ...NavStyles };
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <mUi.Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <mUi.MenuItem onClick={this.handleMenuClose} style={ display }>Profile</mUi.MenuItem>
        <mUi.MenuItem onClick={this.handleMenuClose}>My account</mUi.MenuItem>
      </mUi.Menu>
    );
    const renderMobileMenu = (
      <mUi.Menu
        // style={ display }
        anchorEl={ mobileMoreAnchorEl }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
      
        <mUi.MenuItem onClick={this.handleMobileMenuClose}>
          <mUi.IconButton color="inherit" style={ display }>
            <mUi.Badge badgeContent={11} color="secondary">
              <mUi.NotificationsIcon />
            </mUi.Badge>
          </mUi.IconButton>
          <p>Notifications</p>
        </mUi.MenuItem>
        <mUi.MenuItem onClick={this.handleProfileMenuOpen}>
          <mUi.IconButton color="inherit">
            <mUi.AccountCircle />
          </mUi.IconButton>
          <p>Profile</p>
        </mUi.MenuItem>
      </mUi.Menu>
    );
    
    return (
      <div className={ classes.root }>
        <mUi.AppBar position="static" className={ classes.navbar }>
          <mUi.Toolbar>
            {/* <IconButton className={ classes.menuButton } color="inherit" aria-label="Open drawer">
              <MenuIcon />
            </IconButton> */}
            <mUi.Typography className={ classes.title } variant="h6" color="inherit" noWrap>
            <NewLink to='/' title="Program Your Life"/>
            </mUi.Typography>
            
            <div className={ classes.grow } />
            <div className={ classes.sectionDesktop }>
             
              <mUi.IconButton color="inherit" style={display}>
                <mUi.Badge badgeContent={17} color="secondary">
                  <mUi.NotificationsIcon />
                </mUi.Badge>
              </mUi.IconButton>
              <mUi.IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={ this.handleProfileMenuOpen }
                color="inherit"
                style={display}
              >
                <mUi.AccountCircle />
              </mUi.IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <mUi.IconButton className={ classes['shopping-cart'] }aria-haspopup="true" onClick={ e => { this.openShoppingCart(e); this.setState({ isShoppingCartOpen: true})} } color="inherit">
                {
                  cart.length !== 0 ? (
                    <mUi.Badge badgeContent={ cart.length } color="secondary">
                      <mUi.ShoppingCartIcon />
                    </mUi.Badge>
                  ) : <mUi.ShoppingCartIcon />
                }
              </mUi.IconButton>
              <mUi.Typography className={ classes.initials } variant="h6" color="inherit" noWrap>
                <NewLink to='/' title='PYL'/>
              </mUi.Typography>
            </div>
          </mUi.Toolbar>
        </mUi.AppBar>
        {renderMenu}
        {renderMobileMenu}

        {/* 
          //? this is what you need to do to have anchors in your page
        <Router history={history}>

          <Link  to='/#second-panel'>LOOK </Link>
        </Router> */}
        <mUi.ClickAwayListener onClickAway={ this.handleClickAway }>
          <ShoppingCart anchorEl={ shoppingCartAnchorEl } open={ isShoppingCartOpen } close={ this.handleClickAway }/>
        </mUi.ClickAwayListener>
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default mUi.withStyles(styles)(Navbar);