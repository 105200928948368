import React, { Component } from 'react';
import SwipeableTextMobileStepper from '../SwipeableTextMobileStepper';

import styles from './SecondPanel.module.css';

export default class SecondPanel extends Component {

  render () {
    return (
      <div id='second-panel' className={ styles.panel }>
        <div className={ styles.wrap }>
        <h2>More Products</h2>
          <SwipeableTextMobileStepper content={''}/>
        </div>
      </div>
    );
  }
}