import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import cart from '../ShoppingCart/cart.json'
import styles from './Product.module.css';
import heroStyles from '../HeroProduct/HeroProduct.module.css';
import NewLink from '../Router/NewLink';

export default class Product extends Component {
  addToCart = item => console.log(item);
  render () {
    const foundItem = cart.find(item => item['short-name'] === this.props.match.params.id);
    const productTemplate = foundItem ? renderProduct(foundItem) : unavailableProduct;
    const productDetails = foundItem && renderDetails(foundItem, this.addToCart);
    const itemTitle = foundItem && renderTitle(foundItem);
    return (
      <div className={ styles.wrapper }>
        <Grid
          container
          spacing={ 40 }
          justify='center'
          alignItems='center'
          className={ `${ heroStyles.grid } ${ styles.grid } ${ styles.unavailableWindow }` }
        >
          { itemTitle }
          { productTemplate }
          { productDetails }
        </Grid>
      </div>
    );
  }
}

const renderDetails = (foundItem, add) => (
  <Grid item xs={ 12 } sm={ 6 } styles={ styles.productDetailsGrid }>
    <div className={ styles.productDetails }>
      <p>{ foundItem.description }</p>
      <div className={ styles.priceAndAdd }>
        <hr></hr>
        <h4>Price</h4>
        <p>{ `$${foundItem.price}` }</p>
        <span className={ styles.addToCart } onClick={ e => add(foundItem) }>Add to Cart</span>
        <p><NewLink to='/checkout' title='Go to cart'/></p>
      </div>
    </div>
  </Grid>
);

const renderProduct = foundItem => (
  <Grid item xs={ 12 } sm={ 6 } className={ styles.imgGrid }>
    <img
      alt={ `${foundItem['short-name']}` }
      src={ foundItem.img }
      className={ styles.picture }
      />
  </Grid>
);

const renderTitle = foundItem => (
  <Grid item xs={ 12 } className={ styles.title }>
    <h1>{ foundItem.item }</h1>
  </Grid>
);

const unavailableProduct = (
  <Grid item xs={ 9 } sm={ 6 } className={ styles.unavailableProduct }>
    <img
      className={ styles.lostImg }
      alt='shrugging'
      src='https://user-images.githubusercontent.com/26694930/55921265-019b7f00-5bca-11e9-803f-6c7c420ce58f.gif'/>
    <h2>We searched everywhere but couldn't find that</h2>
  </Grid>
);