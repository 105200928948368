import React from 'react';
import Menu from '@material-ui/core/Menu';
import styles from './ShoppingCart.module.css';
import cart from './cart.json';
import NewLink from '../Router/NewLink';

const ShoppingCart = props => {
  const cartTotal = cart.reduce((val, currentVal) => val + currentVal.price, 0);
  return (
    <Menu
      anchorEl={ props.anchorEl }
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={ props.open }
    >
      <h5 className={ styles.title }>Shopping Cart</h5>
      <table>
        <thead>
          <tr>
            <th className={ styles.item }>Item</th>
            <th>Qty</th> 
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          { cart.map((item, i) => (
              <tr key={ i }>
                <td className={ styles.item }>{ item.item }</td>
                <td className={ styles.quantity }>{ item.quantity }</td> 
                <td className={ styles.price }>{ (item.price).toFixed(2) }</td>
              </tr>
            )) }
          <tr>
              <td><h5>Subtotal</h5></td>
              <td></td> 
              <td className={ styles.price }><h5>{ parseFloat(cartTotal).toFixed(2) }</h5></td>
            </tr>
        </tbody>
      </table>
      <div className={ styles.footer }> 
        { cart.length === 0 ?
            null :
              <h5 className={ styles.btns }><NewLink to='/checkout' title='Checkout'/></h5>
        }
        <h5 className={ styles.btns } onClick={ props.close }>Close</h5>
      </div>
    </Menu>
  )
}

export default ShoppingCart;