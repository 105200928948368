import LandingPage from './LandingPage';
import Checkout from './Checkout';
import Product from './Product';

const routes = [
  {
    path: '/',
    component: LandingPage,
  },
  {
    path: '/checkout',
    component: Checkout,
  },
  {
    path: '/product/:id',
    component: Product
  },
  // {
  //   redirect: true,
  //   path: '/',
  //   component: LandingPage,
  // }
];

export default routes;