import React, { Component } from 'react';
import styles from './App.module.css';
import Navbar from './Navbar';
import routes from './routes';
import { Router, Route, Redirect } from "react-router-dom";
import history from "./history";
import Footer from './Footer';

class App extends Component {
  state = {
    open: false,
  }

  componentDidMount() {
    //? Todo: for closing when clicking outside of cart
    document.getElementById('body').addEventListener('click', function(e){
      //? close chopping cart
    });
  }

  toggleOpen = () => {
    this.state.open ? this.setState({ open: false }) : this.setState({ open: true });
  };

  render() {
    // const handleClick = () => {
    //   this.setState({ open: true });
    // }
    return (
      <div className={styles.App}>
        <Navbar />
          <Router history={ history }>
              {routes.map((route, i) => (<Route key={ i } exact path={ route.path } component={ route.component } />))}
              {/* <Route path='*' component={ LandingPage }/> */}
          </Router>
        <Footer />
      </div>
    );
  }
}

export default App;