import React, { Component } from 'react';
import styles from './Footer.module.css';
import moment from 'moment';

export default class Footer extends Component {
  render () {
    return(
      <div id='footer'>
        <footer className={styles.footer}><a href='http://www.gtng.tech'>GTNG© {moment().format('YYYY')}</a></footer>
      </div>
    )
  }
}